//
//
//
//
//
//
//
//
//

export default {
    name: 'AudioPlayer',
    props: ['data', 'animation'],
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
            audio: null,
            time: null,
            duration: null,
            audioPause: true,
            autoStarted: false,
        };
    },
    methods: {
        setProgress(percent) {
            const circumference = this.$refs.circle.r.baseVal.value * 2 * Math.PI;
            const offset = circumference - (percent / 100) * circumference;
            this.$refs.circle.style.strokeDashoffset = offset;
        },
        formatSecondsAsTime(secs) {
            let hr = Math.floor(secs / 3600);
            let min = Math.floor((secs - hr * 3600) / 60);
            let sec = Math.floor(secs - hr * 3600 - min * 60);

            if (min < 10) {
                min = '0' + min;
            }
            if (sec < 10) {
                sec = '0' + sec;
            }
            return min + ':' + sec;
        },
        startAudio() {
            this.audio = new Audio(`${this.mediaPath}${this.data.audioFile.fullpath}`);
            let promise = this.audio.play();
            if (promise === undefined) return;
            promise
                .then((_) => {
                    // Autoplay started!
                    this.audioPause = false;
                    this.audio.addEventListener(
                        'timeupdate',
                        () => {
                            this.setProgress((parseFloat(this.audio.currentTime) / parseFloat(this.audio.duration)) * 100);
                            this.time = this.formatSecondsAsTime(this.audio.currentTime);
                            this.duration = this.formatSecondsAsTime(this.audio.duration);
                        },
                        false
                    );
                })
                .catch((error) => {
                    // Autoplay was prevented.
                    console.log('error startAudio: ', error);
                });

            this.audio.addEventListener(
                'ended',
                () => {
                    this.audioPause = true;
                },
                false
            );
        },
        toggleAudio() {
            if (this.audio) {
                if (this.audio.paused) {
                    let promise = this.audio.play();
                    if (promise === undefined) return;
                    promise
                        .then((_) => {
                            // Autoplay started!
                            this.audioPause = false;
                        })
                        .catch((error) => {
                            // Autoplay was prevented.
                            console.log(error);
                        });
                } else {
                    let promise = this.audio.pause();
                    if (promise === undefined) {
                        return (this.audioPause = true);
                    }
                    promise
                        .then((_) => {
                            // Autoplay stopped!
                            this.audioPause = true;
                        })
                        .catch((error) => {
                            // Stop was prevented.
                            console.log(error);
                        });
                }
            } else {
                this.startAudio();
            }
        },
    },
    mounted() {
        console.log(this.data)
        if (this.$refs.circle) {
            const circumference = this.$refs.circle.r.baseVal.value * 2 * Math.PI;
            this.$refs.circle.style.strokeDasharray = `${circumference} ${circumference}`;
            this.$refs.circle.style.strokeDashoffset = `${circumference}`;
            this.setProgress(0);
        }
        if (this.data.audioFile) {
            const intersectOptions = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0,
            };
            document.getElementById('');
            let observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    if (this.autoStarted) return;
                    this.startAudio();
                }
            }, intersectOptions);
            observer.observe(this.$refs.audio);
        }
    },
};
